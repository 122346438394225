@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800;900&display=swap');

.spinner{
    position: absolute;
    margin-left: -15%;
    left: 25%;
    background-color:#6e7c7c75;
    border-radius: 8px;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    text-align: center;
}