.header{

    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color:#000;
}

.header p{
    text-align: center;
}
div.sidebar{
    max-width: 200px;
    width: 100%;
    padding: 30px 0;
    position: sticky;
    top: 0;
    z-index: 9;
    background: #000 ;
    max-height: 100vh;
    box-shadow: 0px 5.06787px 17.7376px rgba(0, 0, 0, 0.25);
    border-radius: 0px 15px 15px 0px;
}


.footer-sidebar{
    bottom: 26px;
    position: absolute;
    width: 100%;
    padding: 0 15px;
    text-align: center;
    .horas {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px ;
      }
    button.logout{
        background-color: white;
        padding: 5px 30px;
        border-radius: 8px;
        text-align: center;
        color: #6E7C7C;    
    }

}

.links{
    text-align: left;
    color: white;
    font-weight: 600;
    margin-top: 30px;
}

ul{
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: 50px;
}

ul li a{
    margin-left: 30px;
    display: inline-flex;
    align-items: center;
    padding: 8px;
}

ul li a span{
    padding-left: 8px;
}

.menu-icon{
    font-size: 30px;
    color: #1A25FF;
}


